<template>
  <div>
    <v-dialog v-model="dialog.coating_after_add" max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="cm-add-btn"
          depressed
          color="#CFD3FE"
        >
          <v-icon left>mdi-plus</v-icon>
          内容追加
        </v-btn>
      </template>
      <v-card class="pa-10">
        <v-card-title class="justify-space-between mb-5">
          <div class="page-title font-weight-bold">
            アフター対応
          </div>
          <v-btn
            color="#757575"
            min-width="12"
            max-width="12"
            @click="dialog.coating_after_add = false"
            text
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="customer-box py-10 px-16 mb-10">
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                日付
              </v-col>
              <v-col cols="8" class="flex-grow-1">
                <v-dialog
                  ref="dialogDate"
                  v-model="dialog.date"
                  :return-value.sync="data.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.date"
                      readonly
                      class="form-text"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      hide-details
                      outlined
                      placeholder="日付選択"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :day-format="formatDate"
                    v-model="data.date"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog.date = false">
                      キャンセル
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialogDate.save(data.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                対応種別
              </v-col>
              <v-col cols="9">
                <v-radio-group
                  v-model="data.is_paid"
                  row
                  hide-details="auto"
                  class="mt-0 pt-0"
                >
                  <v-radio label="有償" :value="1"></v-radio>
                  <v-radio label="無償" :value="0"></v-radio>
                  <span class="unselect" @click="data.is_paid = null">選択解除</span>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                部屋番号
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="form-text"
                  dense
                  hide-details
                  outlined
                  placeholder=""
                  v-model="data.room_number"
                ></v-text-field>
              </v-col>
              <v-col cols="1">号室</v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                依頼項目
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-radio-group row hide-details class="mt-0 pt-0">
                  <template v-for="(subItem, subIndex) in items.reasons">
                    <InputCheckbox
                      :key="`checkbox-reason-${subIndex}`"
                      :subItem="subItem"
                      :dataModel.sync="data.reason"
                    />
                    <template
                      v-if="
                        subItem.extra_type &&
                          getIndexByItem(data.reason, subItem) != -1
                      "
                    >
                      <v-text-field
                        :key="`textfield-reason-${subIndex}`"
                        v-if="subItem.extra_type"
                        v-model="
                          data.reason[getIndexByItem(data.reason, subItem)]
                            .text_value
                        "
                        dense
                        outlined
                        class="form-text ml-2 mr-2 mb-2"
                        hide-details
                        height="10"
                        @input="changeInput($event, data.reason, subItem)"
                      >
                      </v-text-field>
                    </template>
                  </template>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                対応方法
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-radio-group row hide-details class="mt-0 pt-0">
                  <template v-for="(subItem, subIndex) in items.methods">
                    <InputCheckbox
                      :key="`checkbox-method-${subIndex}`"
                      :subItem="subItem"
                      :dataModel.sync="data.method"
                    />
                    <template
                      v-if="
                        subItem.extra_type &&
                          getIndexByItem(data.method, subItem) != -1
                      "
                    >
                      <v-text-field
                        :key="`textfield-method-${subIndex}`"
                        v-if="subItem.extra_type"
                        v-model="
                          data.method[getIndexByItem(data.method, subItem)]
                            .text_value
                        "
                        dense
                        outlined
                        class="form-text ml-2 mr-2 mb-2"
                        hide-details
                        height="10"
                        @input="changeInput($event, data.method, subItem)"
                      >
                      </v-text-field>
                    </template>
                  </template>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                対応結果
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-radio-group row hide-details class="mt-0 pt-0">
                  <template
                    v-for="(subItem, subIndex) in items.after_treatments"
                  >
                    <InputCheckbox
                      :key="`checkbox-after_treatment-${subIndex}`"
                      :subItem="subItem"
                      :dataModel.sync="data.after_treatment"
                    />
                    <template
                      v-if="
                        subItem.extra_type &&
                          getIndexByItem(data.after_treatment, subItem) != -1
                      "
                    >
                      <v-text-field
                        :key="`textfield-after_treatment-${subIndex}`"
                        v-if="subItem.extra_type"
                        v-model="
                          data.after_treatment[
                            getIndexByItem(data.after_treatment, subItem)
                          ].text_value
                        "
                        dense
                        outlined
                        class="form-text ml-2 mr-2 mb-2"
                        hide-details
                        height="10"
                        @input="
                          changeInput($event, data.after_treatment, subItem)
                        "
                      >
                      </v-text-field>
                    </template>
                  </template>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                対応者初見
              </v-col>
              <v-col cols="8">
                <v-textarea
                  class="form-text"
                  rows="2"
                  dense
                  v-model="data.memo"
                  hide-details
                  outlined
                  placeholder=""
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                対応者
              </v-col>
              <v-col cols="8" class="flex-grow-1">
                <v-combobox
                  class="form-text"
                  v-model="data.user_id"
                  :items="allUsersList"
                  item-text="last_name"
                  dense
                  small-chips
                  hide-details
                  outlined
                  placeholder="未選択"
                  multiple
                >
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.last_name }} {{ item.first_name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <div class="text-body-1">
                      {{ item.last_name }} {{ item.first_name }}
                    </div>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-6"
            text
            @click="dialog.coating_after_add = false"
            :loading="loading"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="#4F55A7"
            class="white--text px-10"
            @click="save"
            :loading="loading"
          >
            登録
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import InputCheckbox from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/EditSite/Partial/Input/InputCheckbox'
import {mapGetters} from "vuex";
export default {
  name: 'DialogAddCoatingAfter',
  components: { InputCheckbox },
  props: ['project', 'customer_id'],
  computed: {
    ...mapGetters(['allUsersList']),
  },
  data() {
    return {
      dialog: {
        coating_after_add: false,
        date: false
      },

      items: {
        after_treatments: [
          {
            text: '成功',
            value: 0
          },
          {
            text: '微妙',
            value: 1
          },
          {
            text: 'お客様納得',
            value: 2
          },
          {
            text: '失敗',
            value: 3
          },
          {
            text: '状態の説明',
            value: 4
          },
          {
            text: 'その他',
            extra_type: 'with_text',
            value: 5
          }
        ],
        reasons: [
          {
            text: 'ゴミ混入',
            value: 0
          },
          {
            text: '張替',
            value: 1
          },
          {
            text: 'キズ',
            value: 2
          },
          {
            text: 'ムラ',
            value: 3
          },
          {
            text: 'カスレ・あな',
            value: 5
          },
          {
            text: 'その他',
            extra_type: 'with_text',
            value: 4
          }
        ],
        methods: [
          {
            text: '訪問再コート',
            value: 0
          },
          {
            text: '社内持ち込み再コート',
            value: 1
          },
          {
            text: 'スプレーぼかし',
            value: 2
          },
          {
            text: 'リペア含む',
            value: 3
          },

          {
            text: '剥離再コート',
            value: 4
          },
          {
            text: 'アルコール除去',
            value: 5
          },
          {
            text: '重ね散布',
            value: 6
          },

          {
            text: 'その他',
            extra_type: 'with_text',
            value: 7
          }
        ]
      },
      loading: false,
      data: {
        date: null,
        room_number: null,
        is_paid: 0,
        reason: [],
        after_treatment: [],
        user_id: null,
        method: [],
        memo: null
      }
    }
  },
  methods: {
    save() {
      let formData = this.data
      formData.svc_coating_id = this.project.svc_coating_id
      formData.user_id = this.data?.user_id?.map(user => user.id)
      this.loading = true
      this.$store
        .dispatch('CREATE_COATING_AFTER', formData)
        .then(
          response => {
            if (response.data.status === 'success') {
              let payload = {
                status: response.data.status,
                message: response.data.data.message
              }
              this.$store.commit('setAlert', payload)
              this.$store.dispatch('CUSTOMER_GET', this.customer_id)
              this.$emit('update:list')
              this.data = {
                date: null,
                room_number: null,
                is_paid: 0,
                reason: [],
                after_treatment: [],
                user_id: null,
                method: [],
                memo: null
              }
              this.dialog.coating_after_add = false
            }
          },
          error => {
            let payload = {
              status: 'error',
              message: 'Something error, please try again'
            }
            this.$store.commit('setAlert', payload)
            throw error
          }
        )
        .finally(() => {
          this.dialog.coating_after_add = false
          this.loading = false
        })
    },

    getIndexByItem(data, item) {
      return data
        .map(item => `${item.text}${item.id}`)
        .indexOf(`${item.text}${item.id}`)
    },

    changeInput(e, data, item) {
      let d = data[this.getIndexByItem(data, item)]
      if (d.hasOwnProperty('text_value') && !e) {
        delete d.text_value
        return
      }
      d.text_value = e
    },
    formatDate(e) {
      return dayjs(e).format('D')
    }
  },
  created() {
    this.site_id = this.$route.params.site_id
  }
}
</script>
<style lang="scss" src="./AddCoatingAfter.scss" scoped></style>
