var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('DialogEditCoatingAfter', {
    attrs: {
      "shown": _vm.editServiceDialog,
      "coating-after": _vm.selectedCoatingAfter
    },
    on: {
      "update:shown": function updateShown($event) {
        _vm.editServiceDialog = $event;
      },
      "close-dialog": function closeDialog($event) {
        _vm.editServiceDialog = false;
      },
      "click:cancel": _vm.closeEditServiceDialog,
      "update:list": function updateList($event) {
        return _vm.updateCustomerAfterServices(1);
      }
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Table', {
    staticClass: "font-weight-medium mb-4",
    attrs: {
      "loading": _vm.customerAfterServiceLoading,
      "headers": [],
      "items": _vm.afterServices,
      "total-records": _vm.pagination.records_total,
      "number-of-pages": _vm.pagination.total_pages,
      "footer": false
    },
    on: {
      "pagination:update": _vm.updateCustomerAfterServices
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [items.length > 0 ? _c('tbody', [_c('div', {
          staticClass: "table-head"
        }, [_c('div', {
          staticClass: "table-content-top d-flex justify-center align-center"
        }, [_vm._v(" # ")])]), _vm._l(items, function (item, index) {
          return _c('div', {
            key: "".concat(index, "-").concat(item.name),
            staticClass: "d-flex align-center single-row justify-space-between"
          }, [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "idx-number"
          }, [_c('span', [_vm._v(" " + _vm._s(_vm.startIndex + index) + " ")])]), _c('div', {
            staticClass: "single-record d-flex"
          }, [_c('div', {
            staticClass: "record"
          }, [_c('div', {
            staticClass: "d-flex first-row"
          }, [_c('span', [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.date)) + " ")]), _c('div', {
            staticClass: "d-flex"
          }, [_c('span', {
            staticClass: "column-key"
          }, [_vm._v("対応種別：")]), _c('span', [_vm._v(_vm._s(_vm.paidStatus[item.is_paid]))])]), _c('div', {
            staticClass: "d-flex"
          }, [_c('span', {
            staticClass: "column-key"
          }, [_vm._v("部屋番号：")]), _c('span', [_vm._v(_vm._s(item.room_number))])])]), _c('div', [_c('span', {
            staticClass: "column-key"
          }, [_vm._v("依頼項目：")]), _c('span', [_vm._v(" " + _vm._s(_vm.showText(item.reason)) + " ")])]), _c('div', [_c('span', {
            staticClass: "column-key"
          }, [_vm._v("対応方法：")]), _c('span', [_vm._v(_vm._s(_vm.showText(item.method)) + " ")])]), _c('div', [_c('td', [_c('span', {
            staticClass: "column-key"
          }, [_vm._v("対応結果：")]), _c('span', [_vm._v(" " + _vm._s(_vm.showText(item.after_treatment)) + " ")])])]), _c('div', [_c('td', [_c('span', {
            staticClass: "column-key"
          }, [_vm._v("対応者：")]), _c('span', [_vm._v(" " + _vm._s(_vm.showUser(item.users)))])])]), _c('div', {
            staticClass: "d-flex"
          }, [_c('span', {
            staticClass: "column-key"
          }, [_vm._v("対応者初見：")]), _c('span', {
            staticClass: "white-space-pre"
          }, [_vm._v(_vm._s(item.memo || '-'))])])])])]), _c('div', {
            staticClass: "edit"
          }, [_c('v-btn', {
            attrs: {
              "color": "primary",
              "x-small": "",
              "fab": ""
            },
            on: {
              "click": function click($event) {
                return _vm.editService(item);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": "",
              "color": "#fff"
            }
          }, [_vm._v(" mdi-pencil ")])], 1)], 1)]);
        })], 2) : _c('tbody', [_c('tr', [_c('td', {
          staticStyle: {
            "text-align": "center"
          }
        }, [_vm._v(" 表示する項目がありません。 ")])])])];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mb-6"
        }, [_c('v-row', {
          attrs: {
            "align": "end"
          }
        }, [_c('v-col', {
          staticClass: "text-heading-1",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 物件に関連したアフター対応一覧 ")]), _c('v-col', {
          staticClass: "ml-4 text-heading-3",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 全 (" + _vm._s(_vm.pagination.records_total) + ") 件 あります。 ")]), _c('v-col', {
          staticClass: "flex-grow-1 text-right",
          attrs: {
            "cols": "auto"
          }
        }, [_c('DialogAddCoatingAfter', {
          attrs: {
            "allUsersList": _vm.allUsersList,
            "project": _vm.project,
            "customer_id": _vm.customer.id
          },
          on: {
            "update:list": function updateList($event) {
              return _vm.updateCustomerAfterServices(1);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }