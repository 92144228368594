<template>
  <v-checkbox
    :label="subItem.text || subItem.name"
    :value="subItem"
    v-model="data"
    class="mx-2 mt-0"
    hide-details
  >
  </v-checkbox>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  name: 'InputCheckbox',
  props: {
    dataModel: {
      required: true
    },
    subItem: {
      required: true
    }
  },
  created() {
    if(this.subItem.text_value === undefined) {
      delete this.subItem.text_value
    }
  },
  computed: {
    data: {
      get() {
        let data = cloneDeep(this.dataModel)
        return data.map(item => {
          if (item.text_value) delete item.text_value
          return {
            ...item
          }
        })
      },
      set(value) {
        let dataModel = cloneDeep(this.dataModel)
        let val = cloneDeep(value)
        val.forEach(v => {
          let data = dataModel.find(item => item.id === v.id)
          if (data && data.hasOwnProperty('text_value')) {
            v.text_value = data.text_value
          }
        })
        return this.$emit('update:dataModel', val)
      }
    }
  }
}
</script>
