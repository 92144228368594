var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "value": _vm.shown,
      "max-width": "1200px"
    },
    on: {
      "click:outside": function clickOutside($event) {
        return _vm.$emit('update:shown', false);
      }
    }
  }, [_vm.data ? _c('v-card', {
    staticClass: "pa-10"
  }, [_c('v-card-title', {
    staticClass: "justify-space-between mb-5"
  }, [_c('div', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v(" アフター対応 ")]), _c('v-btn', {
    attrs: {
      "color": "#757575",
      "min-width": "12",
      "max-width": "12",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('update:shown', false);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('div', {
    staticClass: "customer-box py-10 px-16 mb-10"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 日付 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-dialog', {
    ref: "dialogDate",
    attrs: {
      "return-value": _vm.data.date,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.data, "date", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.data, "date", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "form-text",
          attrs: {
            "readonly": "",
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": "日付選択"
          },
          model: {
            value: _vm.data.date,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "date", $$v);
            },
            expression: "data.date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1898598243),
    model: {
      value: _vm.dialog.date,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "date", $$v);
      },
      expression: "dialog.date"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "day-format": _vm.formatDate,
      "scrollable": ""
    },
    model: {
      value: _vm.data.date,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "date", $$v);
      },
      expression: "data.date"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.date = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dialogDate.save(_vm.data.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 対応種別 ")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "row": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.data.is_paid,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "is_paid", $$v);
      },
      expression: "data.is_paid"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "有償",
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": "無償",
      "value": 0
    }
  }), _c('span', {
    staticClass: "unselect",
    on: {
      "click": function click($event) {
        _vm.data.is_paid = -1;
      }
    }
  }, [_vm._v("選択解除")])], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 部屋番号 ")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": ""
    },
    model: {
      value: _vm.data.room_number,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "room_number", $$v);
      },
      expression: "data.room_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_vm._v("号室")])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 依頼項目 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "row": "",
      "hide-details": ""
    }
  }, [_vm._l(_vm.items.reasons, function (subItem, subIndex) {
    return [_c('InputCheckbox', {
      key: "checkbox-reason-".concat(subIndex),
      attrs: {
        "subItem": subItem,
        "dataModel": _vm.data.reason
      },
      on: {
        "update:dataModel": function updateDataModel($event) {
          return _vm.$set(_vm.data, "reason", $event);
        },
        "update:data-model": function updateDataModel($event) {
          return _vm.$set(_vm.data, "reason", $event);
        }
      }
    }), subItem.extra_type && _vm.getIndexByItem(_vm.data.reason, subItem) != -1 ? [subItem.extra_type ? _c('v-text-field', {
      key: "textfield-reason-".concat(subIndex),
      staticClass: "form-text ml-2 mr-2 mb-2",
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": "",
        "height": "10"
      },
      on: {
        "input": function input($event) {
          return _vm.changeInput($event, _vm.data.reason, subItem);
        }
      },
      model: {
        value: _vm.data.reason[_vm.getIndexByItem(_vm.data.reason, subItem)].text_value,
        callback: function callback($$v) {
          _vm.$set(_vm.data.reason[_vm.getIndexByItem(_vm.data.reason, subItem)], "text_value", $$v);
        },
        expression: "\n                        data.reason[getIndexByItem(data.reason, subItem)]\n                          .text_value\n                      "
      }
    }) : _vm._e()] : _vm._e()];
  })], 2)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 対応方法 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "row": "",
      "hide-details": ""
    }
  }, [_vm._l(_vm.items.methods, function (subItem, subIndex) {
    return [_c('InputCheckbox', {
      key: "checkbox-method-".concat(subIndex),
      attrs: {
        "subItem": subItem,
        "dataModel": _vm.data.method
      },
      on: {
        "update:dataModel": function updateDataModel($event) {
          return _vm.$set(_vm.data, "method", $event);
        },
        "update:data-model": function updateDataModel($event) {
          return _vm.$set(_vm.data, "method", $event);
        }
      }
    }), subItem.extra_type && _vm.getIndexByItem(_vm.data.method, subItem) != -1 ? [subItem.extra_type ? _c('v-text-field', {
      key: "textfield-method-".concat(subIndex),
      staticClass: "form-text ml-2 mr-2 mb-2",
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": "",
        "height": "10"
      },
      on: {
        "input": function input($event) {
          return _vm.changeInput($event, _vm.data.method, subItem);
        }
      },
      model: {
        value: _vm.data.method[_vm.getIndexByItem(_vm.data.method, subItem)].text_value,
        callback: function callback($$v) {
          _vm.$set(_vm.data.method[_vm.getIndexByItem(_vm.data.method, subItem)], "text_value", $$v);
        },
        expression: "\n                        data.method[getIndexByItem(data.method, subItem)]\n                          .text_value\n                      "
      }
    }) : _vm._e()] : _vm._e()];
  })], 2)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 対応結果 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "start"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "row": "",
      "hide-details": ""
    }
  }, [_vm._l(_vm.items.after_treatments, function (subItem, subIndex) {
    return [_c('InputCheckbox', {
      key: "checkbox-after_treatment-".concat(subIndex),
      attrs: {
        "subItem": subItem,
        "dataModel": _vm.data.after_treatment
      },
      on: {
        "update:dataModel": function updateDataModel($event) {
          return _vm.$set(_vm.data, "after_treatment", $event);
        },
        "update:data-model": function updateDataModel($event) {
          return _vm.$set(_vm.data, "after_treatment", $event);
        }
      }
    }), subItem.extra_type && _vm.getIndexByItem(_vm.data.after_treatment, subItem) != -1 ? [subItem.extra_type ? _c('v-text-field', {
      key: "textfield-after_treatment-".concat(subIndex),
      staticClass: "form-text ml-2 mr-2 mb-2",
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": "",
        "height": "10"
      },
      on: {
        "input": function input($event) {
          return _vm.changeInput($event, _vm.data.after_treatment, subItem);
        }
      },
      model: {
        value: _vm.data.after_treatment[_vm.getIndexByItem(_vm.data.after_treatment, subItem)].text_value,
        callback: function callback($$v) {
          _vm.$set(_vm.data.after_treatment[_vm.getIndexByItem(_vm.data.after_treatment, subItem)], "text_value", $$v);
        },
        expression: "\n                          data.after_treatment[\n                            getIndexByItem(data.after_treatment, subItem)\n                          ].text_value\n                        "
      }
    }) : _vm._e()] : _vm._e()];
  })], 2)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 対応者初見 ")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-textarea', {
    staticClass: "form-text",
    attrs: {
      "rows": "2",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": ""
    },
    model: {
      value: _vm.data.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "memo", $$v);
      },
      expression: "data.memo"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 対応者 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-combobox', {
    staticClass: "form-text",
    attrs: {
      "items": _vm.allUsersList,
      "item-text": "last_name",
      "dense": "",
      "small-chips": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "未選択",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var attrs = _ref2.attrs,
            item = _ref2.item,
            parent = _ref2.parent,
            selected = _ref2.selected;
        return [item === Object(item) ? _c('v-chip', _vm._b({
          attrs: {
            "input-value": selected,
            "label": "",
            "small": ""
          }
        }, 'v-chip', attrs, false), [_c('span', {
          staticClass: "pr-2"
        }, [_vm._v(" " + _vm._s(item.last_name) + " " + _vm._s(item.first_name) + " ")]), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(" $delete ")])], 1) : _vm._e()];
      }
    }, {
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-body-1"
        }, [_vm._v(" " + _vm._s(item.last_name) + " " + _vm._s(item.first_name) + " ")])];
      }
    }], null, false, 921408506),
    model: {
      value: _vm.data.users,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "users", $$v);
      },
      expression: "data.users"
    }
  })], 1)], 1)], 1)]), _c('v-card-actions', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "#AD4545",
      "text": ""
    },
    on: {
      "click": _vm.remove
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$trash")]), _vm._v(" 削除する ")], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "mr-6",
    attrs: {
      "text": "",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click:cancel');
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    staticClass: "white--text px-10",
    attrs: {
      "color": "#4F55A7",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 登録 ")])], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }