var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-checkbox', {
    staticClass: "mx-2 mt-0",
    attrs: {
      "label": _vm.subItem.text || _vm.subItem.name,
      "value": _vm.subItem,
      "hide-details": ""
    },
    model: {
      value: _vm.data,
      callback: function callback($$v) {
        _vm.data = $$v;
      },
      expression: "data"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }